<template>
  <div class="container">
    <div class="mains seeking-publish">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
               label-position="right" :disabled="disableForm">

        <div class="cards">
          <el-form-item label="分类选择" required>
            <el-row class="choose-class">
              <!-- 服务平台 -->
              <el-col :span="8">
                <el-form-item label="服务平台：" prop="platformId">
                  <el-popover :disabled="disableForm" placement="bottom">
                    <el-tabs v-model="selectedServicePlatformGroup" tab-position="left" @tab-click="clickServicePlatformTab">
                      <el-tab-pane v-for="(item, index) in labeList" :key="index" :label="item.label" :name="item.name">
                        <div style="display: flex; width: 400px; flex-wrap: wrap">
                          <el-tag style="margin-bottom: 10px; margin-right: 7px; cursor: pointer;"
                                  v-for="(item, index) in renderedServicePlatformList" :key="index"
                                  :effect="selectedServicePlatform === index ? 'dark' : 'plain'" @click="clickServicePlatform(item, index)">
                            {{ item.platformName }}
                          </el-tag>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                    <el-input placeholder="请选择服务平台" v-model="platform" suffix-icon="el-icon-arrow-down"
                              clearable
                              slot="reference" @clear="selectedServicePlatform = null"></el-input>
                  </el-popover>
                  <!-- <Tabs></Tabs> -->
                </el-form-item>
              </el-col>
              <!-- 服务类目 -->
              <el-col :span="8">
                <el-form-item label="服务类目：" prop="categoriesId">
                  <el-popover :disabled="disableForm" placement="bottom">
                    <div style="
                        display: flex;
                        width: 310px;
                        flex-wrap: wrap;
                        justify-content: space-between;
                      ">
                      <el-tag style="margin-bottom: 10px; cursor: pointer" v-for="(item, index) in serviceCategories"
                              :key="index" :effect="selectedServiceCategory === item.value ? 'dark' : 'plain'"
                              @click="clickServiceCategory(item)">{{ item.label }}
                      </el-tag>
                    </div>
                    <el-input placeholder="请选择服务类目" v-model="categories" suffix-icon="el-icon-arrow-down"
                              clearable
                              slot="reference" @clear="selectedServiceCategory = null"></el-input>
                  </el-popover>
                </el-form-item>
              </el-col>
              <!-- 服务项目 -->
              <el-col :span="8">
                <el-form-item label="服务项目：" prop="itemsId">
                  <el-popover :disabled="disableForm" placement="bottom-end">
                    <el-tabs tab-position="left" v-model="itemsPId">
                      <el-tab-pane v-for="(parentItem, index) in serviceItemsList" :name="'' + parentItem.id" :key="index" :label="parentItem.itemsName">
                        <div style="display: flex; width: 400px; flex-wrap: wrap">
                          <el-tag style="margin: 0 10px 10px 0; cursor: pointer" v-for="(item, index) in parentItem.children"
                                  :key="index" :effect="selectedServiceItem === index ? 'dark' : 'plain'"
                                  @click="clickServiceItem(item, index, parentItem)">{{ item.itemsName }}
                          </el-tag>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                    <el-input placeholder="请选择服务项目" v-model="items" suffix-icon="el-icon-arrow-down" clearable
                              slot="reference" @clear="selectedServiceItem = null"></el-input>
                  </el-popover>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="需求标题" prop="title">
            <el-input v-model="ruleForm.title" type="text" placeholder="请简要概括需求内容，如：脚本编写" maxlength="50"
                      show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="需求详情" prop="describe">
            <el-input type="textarea"
                      placeholder="请详细描述需求内容，便于服务商更好的理解需求。"
                      v-model="ruleForm.describe" maxlength="500" :autosize="{ minRows: 4, maxRows: 6 }"
                      show-word-limit>
            </el-input>
          </el-form-item>
          <el-form-item label="需求图片" prop="img">
            <draggable v-model="uploadList" class="pic-upload-container">
              <image-upload v-for="(item, index) in uploadList" :key="index" v-model="item.img" :fileType="fileType"
                            :limit="1" :isShowTip="false"/>
            </draggable>
          </el-form-item>
          <el-form-item label="交易模式" prop="mode">
            <el-checkbox-group v-model="ruleForm.mode">
              <el-checkbox-button label="计时悬赏">计时悬赏</el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="交易总额" prop="number">
            <el-input v-model="ruleForm.number" placeholder="请输入金额" style="width: 200px"></el-input>
          </el-form-item>
          <el-form-item label="交付时间" prop="endTimeNumber">
            <el-input placeholder="交付时间" v-model.trim.number="ruleForm.endTimeNumber" class="input-with-select">
              <el-select v-model="ruleForm.endTimeType" slot="append" placeholder="请选择">
                <el-option label="小时" value="1"></el-option>
                <el-option label="天" value="2"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
        </div>

        <el-row class="cards" type="flex" justify="center">
          <el-col :span="6" class="bottom">
            <div class="title">应付总金额</div>
            <div class="price">￥<span class="number">{{ ruleForm.number }}</span></div>
            <div class="button-container">
              <el-button @click="clickCancel">取消发布</el-button>
              <el-button type="danger" @click="submitForm('ruleForm')">发布需求</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>

      <el-dialog :visible.sync="publishSucceed" center width="20%"
                 :close-on-click-modal="false"
                 @close="closeDialog"
      >
        <div class="dialog-info success"><i class="el-icon-check"></i></div>
        <div class="dialog-info content">恭喜，需求发布成功</div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="publishSucceed = false">我知道了</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "PublishingNeed",
  components: { draggable },
  data() {
    // region 自定义验证器
    const checkImage = (rule, value, callback) => {
      let arr = []
      this.uploadList.forEach(item => {
        if (item.img) arr.push(item.img)
      })
      if (!arr || !arr.length) return callback(new Error('最少上传一张图片'));
      return callback();
    };
    const checkEndTimeNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('交付时间不能为空'));
      }
      setTimeout(() => {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入数字值'));
        } else {
          if (value < 0) {
            callback(new Error('不能为负数'));
          } else {
            callback();
          }
        }
      }, 300);
    };
    const checkAmount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('交易总额不能为空'));
      }
      if (isNaN(value) || +value <= 0) {
        callback(new Error('交易总额必须为大于零的有效数字'));
      } else {
        callback();
      }
    };
    // endregion
    return {
      // 表单数据
      ruleForm: {
        platformId: "", // 服务平台
        categoriesId: "", // 服务类目
        itemsId: "", // 服务项目
        title: "", // 需求标题
        describe: "", // 需求详情
        mode: '',
        number: '',
        endTimeNumber: '',
        endTimeType: '1',
        userId: "", //用户id
      },
      // 显示名称
      platform: "", // 服务平台
      categories: "", // 服务类目
      items: "", // 服务项目
      disableForm: false, // 服务项目
      publishSucceed: false,
      

      // region 表单验证
      rules: {
        platformId: [
          { required: true, message: "请选择平台", trigger: "change" },
        ],
        categoriesId: [
          { required: true, message: "请选择服务类目", trigger: "change" },
        ],
        itemsId: [
          { required: true, message: "请选择服务项目", trigger: "change" },
        ],
        title: [
          { required: true, message: "请输入需求标题", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        describe: [
          { required: true, message: "请输入需求详情", trigger: "blur" },
          {
            min: 3,
            max: 500,
            message: "长度在 3 到 500 个字符",
            trigger: "blur",
          },
        ],
        mode: [
          { required: false, message: "请选择交易模式", trigger: "change" },
        ],
        img: [
          { required: true, validator: checkImage, trigger: "change" },
        ],
        number: [
          {  required: true, validator: checkAmount, trigger: "blur" },
        ],
        endTimeNumber: [
          { required: true, validator: checkEndTimeNumber, trigger: "change" },
        ],
      },
      // endregion

      fixed: false,
      show: false,
      asideIndex: "key2", //默认初始值
      // servuceCategoryList: [], // 服务类目列表
      // accordingTypeList: [], // 单位列表
      // serviceTagList: [], // 服务标签列表
      // serviceItemsList: [], // 平台列表
      // servuceProjectList: [], // 服务项目列表
      // tagList: [], //标签列表

      // 标签
      labeList:                     [
        {
          label: "全部",
          name: "all",
        },
        {
          label: "国内",
          name: "domestic",
        },
        {
          label: "跨境",
          name: "abroad",
        },
      ],
      renderedServicePlatformList: [],
      selectedServicePlatform:      null, // 平台标签选中状态
      selectedServiceCategory:      null, // 服务类目选择状态
      selectedServiceItem:          null, // 服务项目选择状态
      dark:                         "dark",
      selectedServicePlatformGroup: "all", //默认tab值
      checkTagList:                 [], //选中的标签
      fileType:                     ["png", "jpg", "jpeg", "bmp", "gif"],
      uploadList: [
        {
          img: "",
        },
        {
          img: "",
        },
        {
          img: "",
        },
        {
          img: "",
        },
        {
          img: "",
        },
      ],
      itemsPId: null
    };
  },
  created() {
    if (this.$route.query.need) this.itemsPId = String(this.$route.query.need)
    if (this.$route.query.amount) this.ruleForm.number = +this.$route.query.amount
    if (this.$route.query.id) this.getDetail(this.$route.query.id)

  },

  mounted() {
    // 初始化服务平台为全部
    this.renderedServicePlatformList = this.servicePlatformList;
    
    // 初始化服务项目
    this.itemsPId = '' + this.serviceItemsList[0].id
  },
  computed: {
    servicePlatformList() {
      return this.$store.getters.getConfig.servicePlatformList
    },
    serviceCategories() {
      return this.$store.getters.getConfig.serviceCategories
    },
    serviceItemsList() {
      return this.$store.getters.getConfig.serviceItemsList
    },
    serviceLabels() {
      return this.$store.getters.getConfig.serviceLabels
    },
    serviceEfficiencyUnits() {
      return this.$store.getters.getConfig.serviceEfficiencyUnits
    },
  },
  methods: {
    getDetail(id) {
      this.$axios
          .get(`/app-api/hiring/requirement/get`, {
            params: {
              id: id
            }
          })
          .then((res) => {
            if (res.data.code === 0) {
              let data = res.data.data
              this.ruleForm.platformId = data.platformId
              this.ruleForm.categoriesId = data.categoriesId
              this.ruleForm.itemsId = data.itemsId
              this.ruleForm.title = data.title
              this.ruleForm.describe = data.requirementParticulars
              this.ruleForm.mode = data.type
              this.ruleForm.number = data.amount
              this.ruleForm.endTimeNumber = data.platformId
              this.ruleForm.endTimeType = data.platformId
              this.ruleForm.userId = data.UserId
              if (data.filePath) {
                let imgArr = data.filePath.split(',')
                for (let i = 0; i < imgArr.length; i++) {
                  this.uploadList[i].img = imgArr[i]
                }
              }
            }
          })
          .catch((err) => {
            this.$message.error("获取需求详情失败！" + err)
          });
    },

    // 平台筛选
    clickServicePlatformTab(e) {
      if (e.name === "domestic") {
        this.renderedServicePlatformList = this.servicePlatformList.filter(item => item.platformType === 1);
      } else if (e.name === "abroad") {
        this.renderedServicePlatformList = this.servicePlatformList.filter(item => item.platformType === 2);
      } else {
        this.renderedServicePlatformList = this.servicePlatformList;
      }
    },
    // 选择平台标签
    clickServicePlatform(item, index) {
      this.selectedServicePlatform = index;
      this.ruleForm.platformId = item.id;
      this.platform = item.platformName;
    },

    // 选择服务类目标签
    clickServiceCategory(item) {
      this.selectedServiceCategory = item.value;
      this.ruleForm.categoriesId = item.value;
      this.categories = item.label;
    },

    // 选择服务类目标签
    clickServiceItem(item, index, parentItem) {
      this.selectedServiceItem = index;
      this.ruleForm.itemsId = item.id;
      this.items = item.itemsName;
      this.show = parentItem.id === 13;
    },
    // 给服务类目赋值图片
    PushImage(item) {
      this.uploadList[0].img = item.logoImg;
    },
    getImagePayload() {
      let arr = []
      this.uploadList.forEach(item => {
        if (item.img) arr.push(item.img)
      })
      if (!arr || !arr.length) return ''
      return arr.join(',')
    },

    // 发布
    publish() {
      let url = "/app-api/hiring/requirement/create";
      let payload = {
        "amount": this.ruleForm.number,
        "categoriesId": this.ruleForm.categoriesId,
        // "expirationDate": this.ruleForm.endTime,
        // "expirationDate": moment().add(2, 'days'),
        "filePath": this.getImagePayload(),
        "itemsId": this.ruleForm.itemsId,
        "platformId": this.ruleForm.platformId,
        "type": this.ruleForm.mode ? '1' : '',
        "requirementParticulars": this.ruleForm.describe,
        "title": this.ruleForm.title,
        "userId": this.$store.getters.getUser.id,
        "achieveTimeInt": this.ruleForm.endTimeNumber,
        "achieveTimeType": this.ruleForm.endTimeType,
      }
      this.$axios
          .post(url, payload)
          .then((res) => {
            if (res.data.code === 0) {
              this.$modal.notifySuccess("订单创建成功!");
              this.$router.push({
                name: "DemandPay",
                query: {
                  id: res.data.data,
                },
              });
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },

    // 上传表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.publish()
        } else {
          // this.$message({
          //   message: "表单验证失败！请检查填写内容",
          //   type: "error",
          // });
        }
      });
    },

    clickCancel() {
      this.$router.back()
    },

    // 重置表单
    resetForm() {
      this.platform = "";
      this.categories = "";
      this.items = "";

      this.$refs.ruleForm.resetFields();
      this.$message({
        message: "表单已重置！",
        type: "success",
      });
    },
    itemClass(item) {
      return {
        item: true,
        "is-active": this.uploadList.indexOf(item.img) === this.activeIndex,
      };
    },
    closeDialog() {
      this.$router.push({ name: "SeekingHallPublishList" });
    }
  },
};
</script>
<style lang="less">
.seeking-publish {
  .el-form-item__label {
    font-weight: 600;
  }

  .choose-class {
    .el-form-item__label::before {
      content: '' !important;
    }
  }

  .component-upload-image {
    .el-upload-list__item {
      margin: 0;
    }
  }
  .el-select .el-input {
    width: 80px;
  }
  .input-with-select {
    width: 200px;
  }
}

.el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 4px;
}

</style>
<style lang="less" scoped>

.cards {
  box-shadow: 0 4px 4px 1px rgba(204, 226, 237, 0.5);
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-right: 10px;
}

.pic-upload-container {
  display: flex;

  .component-upload-image {
    & + .component-upload-image {
      margin-left: 8px;
    }

    .el-upload-list__item {
      margin: 0;
    }
  }
}

.bottom {
  text-align: center;

  .title {
    font-weight: 600;
    margin-bottom: 20px;
  }

  .price {
    margin-bottom: 20px;
    font-size: 12px;
    color: #FC3737;

    .number {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
.dialog-info {
  text-align: center;
  i {
    font-size: 80px;
    font-weight: 600;
    color: #FC3737;
  }

  &.content {
    font-size: 16px;
    font-weight: 600;
  }
  & + .dialog-info {
    margin-top: 20px;
  }
}

</style>

